<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="门店名称" prop="name">
            <a-input v-model="rowData.name" placeholder="门店名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="联系人" prop="contacts">
            <a-input v-model="rowData.contacts" placeholder="联系人"></a-input>
          </a-form-model-item>
        </a-col>
        <!--        <a-col :md="12">-->
        <!--          <a-form-model-item label="预定单配额" prop="num">-->
        <!--            <a-input-number-->
        <!--              style="width: 100%"-->
        <!--              :disabled="handle == 'check'"-->
        <!--              v-model="rowData.num"-->
        <!--              :min="0"-->
        <!--              placeholder="请输入预定单配额"-->
        <!--            ></a-input-number>-->
        <!--          </a-form-model-item>-->
        <!--        </a-col>-->
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="门店地址" prop="provinceId">
            <PCD
              ref="pcd"
              placeholder="请选择区域"
              :province.sync="rowData.province"
              :city.sync="rowData.city"
              :district.sync="rowData.area"
              :provinceId.sync="rowData.provinceId"
              :cityId.sync="rowData.cityId"
              :districtId.sync="rowData.areaId"
            >
            </PCD>
          </a-form-model-item>
        </a-col>

        <a-col :md="12">
          <a-form-model-item label="联系电话" prop="mobile">
            <a-input v-model="rowData.mobile" placeholder="联系电话"></a-input>
          </a-form-model-item>
        </a-col>

      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="详细地址" prop="address">
            <a-textarea
              v-model="rowData.address"
              placeholder="详细地址"
              :auto-size="{ minRows: 3, maxRows: 21 }">
            </a-textarea>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="门头照片" prop="imageUrl">
            <QiniuUpload type="image" :value.sync="rowData.imageUrl"></QiniuUpload>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import {editDealerStore, selectByIdDealerStore, addDealerStore} from '../api/DealerStoreApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        name: [{required: true, message: '请输入门店名称', trigger: 'blur'}],
        num: [{required: true, message: '请输入预定单配额', trigger: 'blur'}],
        provinceId: [{required: true, message: '请输入省ID', trigger: 'blur'}],
        address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
        contacts: [{required: true, message: '请输入联系人', trigger: 'blur'}],
        mobile: [{required: true, pattern: new RegExp(/^[0-9]{1,18}$/), message: '请输入联系电话', trigger: 'blur'}],
        //imageUrl: [{ required: true, message: '请输入门头照片', trigger: 'blur' }],
      },
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.$nextTick(() => {
        this.$refs.pcd.setPCD()
      })
      if (handle === 'edit') {
        selectByIdDealerStore(row.id).then((res) => {
          this.rowData = res.body
          if (res.body) {
            this.$set(this.rowData, 'provinceId', res.body.provinceId)
            this.$set(this.rowData, 'province', res.body.province)
            this.$set(this.rowData, 'cityId', res.body.cityId)
            this.$set(this.rowData, 'city', res.body.city)
            this.$set(this.rowData, 'areaId', res.body.areaId)
            this.$set(this.rowData, 'area', res.body.area)
            this.$set(this.rowData, 'address', res.body.address)
            this.$nextTick(() => {
              this.$refs.pcd.setPCD()
            })
          }
        })
      }
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        const res = this.handle === 'add' ? await addDealerStore(this.rowData) : await editDealerStore(this.rowData)
        if (res.code === 200) {
          this.$notification.success({message: res.message})
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({message: res.message})
        }
      })
    },
  },
  created() {
  },
}
</script>

<style lang="less" scoped>
</style>
