/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerStore = params => axios({
    url: '/api/base/store/dealerStore/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerStore = params => axios({
    url: '/api/base/store/dealerStore/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerStore = params => axios({
    url: '/api/base/store/dealerStore/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerStore = params => axios({
    url:'/api/base/store/dealerStore/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerStore = params => axios({
    url: '/api/base/store/dealerStore/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const listDealer = params => axios({
  url: ' /api/base/store/dealerStore/listNotChooseStoreManager',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})
